<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <b-modal size="lg" id="guide" hide-footer>
      <template #modal-title>
        <span style="text-align: left; font-size: 20px"
          >Charts Lab - Guida introduttiva</span
        >
      </template>
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-6 col-xl-12 col-sm-3">
          <iframe
            src="https://app.charts.alpha4all.it/chartslab.html"
            title="Video Guida"
            allow="autoplay; fullscreen"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            class="wistia_embed"
            name="wistia_embed"
            allowfullscreen
            msallowfullscreen
            width="100%"
            height="100%"
            style="min-height: 600px"
          ></iframe>
        </div>
      </div>
    </b-modal>

    <div
      class="topbar-item border rounded m-2 btn btn-success font-weight-bolder"
    >
      <a @click="$bvModal.show('guide')">Video Guida</a>
    </div>
    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
#guide > div {
  max-width: 80% !important;
}
</style>

<script>
/*import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";*/
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
/*import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";*/
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
    };
  },
  components: {
    /*KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTDropdownMyCart,
    KTDropdownLanguage,*/
    KTQuickUser,
    //KTQuickPanel
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://fast.wistia.com/embed/medias/vjjq4hku7t.jsonp"
    );
    document.head.appendChild(recaptchaScript);
    recaptchaScript.setAttribute(
      "src",
      "https://fast.wistia.com/assets/external/E-v1.js"
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
};
</script>
